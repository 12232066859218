.root>*+* {
  margin: 16px;
}

.m4 {
  margin: 40px 40px 0;
}

.linkstyle {
  color: gray;
  text-decoration: none;
}