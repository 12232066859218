.container {
  background-color: #f1f1ef;
}

.displayText {
  color: #FFF;
}

.dividerColor {
  background-color: #FEFEFF;
}

.root {
  font-size: 28px;
  color: #6F6F6F;
  letter-spacing: 0px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px
}

.signin {
  height: 100%;
  max-height: 500px;
  width: 100%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.signInTextBox {
  font-weight: bold;
  font-size: 28px;
  padding: 16px 24px;
  text-align: left;
  color: #335977
}

.linkText {
  color: #026AE3;
  text-decoration: none;
}

.helperText {
  text-align: center;
  color: #4A4A4A;
  font-size: 14px;
}