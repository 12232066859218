.envoy-details-view {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: 1000px;
    min-height: 700px;
    background: var(--cod-gray-variant);
    backdrop-filter: blur(25px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
}

.envoy-details-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 30px;
    gap: 20px;

    position: relative;
    width: 50%;
    height: 50%;
    min-height: 50%;

    /* Light Theme / Light Gray */

    background: var(--white);
    border-radius: 25px;
}

.close-button {
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 10px;
    cursor: pointer;
}

.Nav-bar {
    position: relative;
    height: 20%;
    width: 100%;
    background-color: var(--white);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.envoy-summary {
    position: relative;
    height: fit-content;
    width: 100%;
    background-color: white;
}

.table-envoy-details {
    position: relative;
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
}

.thead-envoy-details {
    position: sticky;
}

.trHead-envoy-details {
    height: 40px;
    background: var(--alto);
}

.trBody-envoy-details {
    height: 40px;
    background: var(--white);
}

.td-envoy-details {
    padding: var(--sm-spacing);
    border: 1px solid var(--bg2);
    font-size: 14px;
}

.paginationBttns-envoy-details {
    position: relative;
    width: 50%;
    height: 20px;
    list-style: none;
    display: flex;
    justify-content: flex-start;
}

.paginationBttns-envoy-details a {
    width: 5px;
    height: 5px;
    margin: 8px;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid var(--black);
    color: var(--black);
    cursor: pointer;
    background-color: white;
    font-size: 20px;
}

.paginationBttns-envoy-details a:hover {
    color: black;
    background-color: var(--ash);
}

.paginationActive-envoy-details a {
    color: black;
    background-color: var(--ash);
}

.paginationDisabled-envoy-details a {
    color: black;
    background-color: grey;
}
