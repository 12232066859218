.table {
  max-height: 500px;
  margin-bottom: 20px;
  border-radius: 0px;
}

.heading {
  color: #335977;
  font-size: 20px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.tableHeader {
  border: none;
  background-color: #335977;
  font-size: 14px;
  letter-spacing: 0.07px;
  font-family: 'Roboto, sans-serif', sans-serif;
  white-space: nowrap;
}

.headerWrapper {
  color: #FFE784;
  font-weight: 500;
  font-size: 14px;
  padding: 0px;
}

.paginationWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: #4A4A4A;
  font-size: 14px;
  flex-wrap: wrap;
}

.paginationSelectWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.selectRoot {
  min-width: 50px;
}

.selectWrapper {
  margin: 0px 10px 0px 20px;
}

.sortIcon {
  color: #FFFFFF;
  margin-left: 2px;
}

.noPointer {
  pointer-events: none;
}

.tableHeading span {
  font-size: '14px'
}

.dropDownValues {
  cursor: pointer;
  padding: 8px 12px;
  font-size: 12px;
}

.popoverPaper {
  width: 220px;
}

.active {
  background-color: #D6D1CE;
  margin: 3px;
  border-radius: 8px;
  color: #000000;
  font-weight: bold
}

.infoIcon {
  font-size: 14px;
  cursor: pointer;
}

.infoPopover {
  color: #4A4A4A;
  font-size: 14px;
  opacity: 0.7;
  text-align: left;
}

.infoPopoverPaper {
  width: 250px;
}