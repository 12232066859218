.container-center-landing-page {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    height: 100%;
    display: flex;
}

.container-center-horizontal > * {
    flex-shrink: 0;
    pointer-events: auto;
}

.screen a {
    display: contents;
    text-decoration: none;
}

.email_id {
    position: relative;
    width: 222px;
    height: 22px;
    top: -50px;
    left: 1000px;

    font-family: var(--font-family-visuelt-regular);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 135%;

    text-align: right;
    letter-spacing: -0.01em;

    color: #000000;
}

.landing-page {
    background-color: var(--seashell);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    scroll-behavior: smooth;
    width: 100%;
    min-width: 100%;
}

.envoy-heading {
    letter-spacing: -0.32px;
    line-height: 120px;
    margin-left: 4%;
    margin-top: 1%;
    white-space: nowrap;
}

.new-request-button {
    align-items: flex-start;
    background-color: var(--cod-gray);
    border: 1px none;
    border-radius: 50px;
    height: 40px;
    width: fit-content;
    margin-left: 4%;
    min-width: 145px;
    padding: 8px 25px;
    cursor: pointer;
}

.request-button {
    letter-spacing: -0.16px;
    min-height: 22px;
    min-width: 95px;
    text-align: center;
    white-space: nowrap;
}

.request-summary {
    width: 90%;
    height: fit-content;
    display: flex;
    margin-left: 4%;
    margin-top: 2%;
}

.table-request-summary {
    width: 100%;
    height: 100%;
    border-collapse: collapse;
    text-align: center;
    border-radius: 8px;
    overflow: hidden;
    min-width: 1000px;
}

.thead-request-summary {
    position: sticky;
}

.trHead {
    height: 80px;
    background: var(--alto);
}

.trBody {
    height: 80px;
    background: var(--white);
}

.td-request-summary {
    padding: var(--sm-spacing);
    border: 1px solid var(--bg2);
    font-size: 14px;
}

.details-css {
    text-decoration-line: underline;
    cursor: pointer;
}

.paginationBttns {
    position: relative;
    width: 40%;
    height: 40px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    margin-left: 4%;
}

.paginationBttns a {
    width: 10px;
    height: 10px;
    padding: 10px;
    margin: 8px;
    border-radius: 5px;
    border: 1px solid var(--black);
    color: var(--black);
    cursor: pointer;
    background-color: white;
    font-size: 20px;
}

.paginationBttns a:hover {
    color: red;
    background-color: var(--ash);
}

.paginationActive a {
    color: red;
    background-color: var(--ash);
}

.paginationDisabled a {
    color: grey;
    background-color: grey;
}
