@font-face {
    font-family: "Enphase Visuelt-Regular";
    font-style: normal;
    font-weight: 400;
}

.error_message {
    letter-spacing: -0.14px;
    line-height: 5px;
    min-height: 5px;
    white-space: nowrap;
    padding: 10px 5px;
}
