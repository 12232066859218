.nav-bar {
    position: relative;
    align-items: center;
    background-color: var(--white);
    border: 1px none;
    display: flex;
    justify-content: space-between;
    height: 72px;
    width: 100%;
    max-width: 100vw;
}

.navbar-logo {
    position: relative;
    height: 40px;
    width: 40px;
    left: 7%;
}

.login_enabled {
    position: relative;
    background-color: var(--white);
    display: flex;
    height: 72px;
    padding: 0px 10px;
    width: fit-content;
    align-items: center;
}

.line-sep {
    padding: 10px;
}

.logout {
    cursor: pointer;
}
