.root {
  display: 'flex';
  justify-content: 'center';
  flex-wrap: 'wrap';
}

.root>* {
  margin: 4px,
}

.yellow {
  background-color: '#FFD800',
}

.green {
  background-color: '#7ACF38',
}

.round {
  border-radius: '10px',
}