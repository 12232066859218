.root {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  width: 100%;
  z-index: 9999;
  background: #000000;
  opacity: 0.5;
  top: 0;
  left: 0;
}