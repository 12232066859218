.login_button {
    align-items: flex-start;
    background-color: var(--cod-gray);
    border: 1px none;
    border-radius: 50px;
    display: flex;
    height: 60px;
    margin-top: 27px;
    overflow: hidden;
    padding: 18px 166px;
    width: 200px;
    cursor: pointer;
    justify-content: center;
}

.login_button:disabled {
    background-color: var(--ash);
    cursor: not-allowed;
}

.continue {
    letter-spacing: -0.16px;
    line-height: 20px;
    min-height: 22px;
    min-width: 68px;
    text-align: center;
    white-space: nowrap;
}
