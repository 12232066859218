.root {
  border-bottom: 1px solid #70707033;
  display: flex;
  width: 100%;
}

.toggleLabel {
  font-size: 20px;
  color: #545456;
  opacity: 0.5;
  font-weight: bold;
  margin: 0px 15px;
  cursor: pointer;
}

.selected {
  color: #335977;
  opacity: 1;
}

.disabled {
  cursor: not-allowed;
}

.highlight {
  height: 8px;
  width: 90%;
  background: #F37321;
  border: 1px solid #F37321;
  border-radius: 100px;
  margin-top: 10px;
  position: relative;
  top: 5px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  min-height: 43px;
}