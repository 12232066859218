.headerWrapper {
  justify-content: space-between;
  align-items: center;
  margin: 24px 40px;
  height: 100%;
}

.title {
  color: #335977;
  font-size: 30px;
  font-weight: bold;
}

.tableContainer {
  box-shadow: 0px 2px 10px #3F434714;
  margin: 24px 40px;
  padding: 20px;
}

.tableHeader {
  color: #335977;
  font-size: 18px;
  font-weight: bold;
}

.columnSelectorIcon {
  cursor: pointer;
}

.headerPaper {
  min-width: 200px;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.popoverHeading {
  color: #626262;
  font-size: 16px;
  font-weight: bold;
}

.button {
  min-height: 28px;
  margin: 10px;
  width: auto;
  min-width: 130px;
}

.cardHeading {
  color: #626262;
  font-size: 14px;
  text-align: center;
  margin: 10px 0px;
}

.systemIdHeader {
  position: sticky;
  left: 0;
  z-index: 99;
}

.systemIdValue {
  position: sticky;
  left: 0;
  background-color: white;
}

.systemNameHeader {
  position: sticky;
  left: 110px;
  z-index: 99;
}

.systemNameValue {
  position: sticky;
  left: 110px;
  background-color: white;
}