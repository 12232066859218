.screen a {
    display: contents;
    text-decoration: none;
}

.container-center-horizontal {
    display: flex;
    flex-direction: row;
    justify-content: center;
    pointer-events: none;
    width: 100%;
}

.container-center-horizontal > * {
    flex-shrink: 0;
    pointer-events: auto;
}

.home {
    align-items: center;
    background-color: var(--seashell);
    border: 1px none;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    position: relative;
    width: 100%;
}

.overlap-group {
    align-items: center;
    background-color: var(--white);
    border-radius: 16px;
    box-shadow: 0px 0px 40px;
    display: flex;
    flex-direction: column;
    margin-top: 86px;
    min-height: 250px;
    padding: 33px 0;
    position: relative;
    width: 478px;
}

.login {
    letter-spacing: 0;
    line-height: normal;
    min-height: 31px;
    min-width: 64px;
    text-align: center;
}

.card {
    align-items: flex-start;
    background-color: var(--pampas);
    border: 1px none;
    border-radius: 25px;
    display: flex;
    height: 35px;
    margin-top: 43px;
    overflow: hidden;
    padding: 14px 25px;
    width: 400px;
}

.mail-id-button {
    height: 40px;
    width: 300px;
    padding: 0px 5px;
    letter-spacing: -0.18px;
    background-color: var(--pampas);
    line-height: 28px;
    opacity: 0.5;
    white-space: nowrap;
    align-items: center;
    border: var(--white);
}