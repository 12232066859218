.job-request {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: 1000px;
    min-height: 800px;
    background: var(--cod-gray-variant);
    backdrop-filter: blur(25px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
}

.content-box{
    display: flex;
    flex-direction: column;
    padding: 60px 30px;
    gap: 30px;

    position: relative;
    width: 45%;
    height: 65%;
    min-height: 75%;


    /* Light Theme / Light Gray */

    background: var(--white);
    border-radius: 25px;
}

.envoy-request {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
    height: 10%;
    background-color: var(--white);
}

.envoy-add-box {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;

    position: relative;
    width: 100%;
    height: 5%;
    background-color: var(--white);
}

.microinvertor-add-box {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    position: relative;

    width: 100%;
    height: 5%;
    background-color: var(--white);
}

.encharge-add-box {
    display: flex;
    position: relative;
    width: 100%;
    height: 5%;
    background-color: var(--black);
}

.enpower-add-box {
    display: flex;
    position: relative;
    width: 100%;
    height: 5%;
    background-color: var(--black);
}

.iq-relay-add-box {
    display: flex;
    position: relative;
    width: 100%;
    height: 5%;
    background-color: var(--black);
}

.acb-add-box {
    display: flex;
    position: relative;
    width: 100%;
    height: 5%;
    background-color: var(--black);
}

.n_sites-add-box {
    display: flex;
    position: relative;
    width: 100%;
    height: 5%;
    background-color: var(--white);
}

.end-time-add-box {
    display: flex;
    position: relative;
    width: 100%;
    height: 5%;
    background-color: var(--white);
}

.battery-mode-add-box {
    display: flex;
    position: relative;
    width: 100%;
    height: 5%;
    background-color: var(--white);
}

.done-add-box {
    display: flex;
    position: relative;
    width: 100%;
    height: 5%;
    background-color: var(--white);
}

.close-button-envoy-request {
    position: relative;
    height: 30px;
    width: 30px;
    border-radius: 10px;
    cursor: pointer;
}

.text-box-envoy-request {
    position: relative;
    width: 25%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    background-color: var(--white);
}

.part-num-box-envoy-request {
    position: relative;
    width: 25%;
    height: 100%;
    display: flex;
    padding-left: 12.5%;
    align-items: flex-end;
    background-color: var(--white);
}

.devices-count-envoy-request {
    position: relative;
    width: 25%;
    height: 100%;
    display: flex;
    padding-left: 12.5%;
    align-items: center;
    background-color: var(--white);
}

.part-num-input {
    width: 100%;
    height: 100%;
    padding: 0px 5px;
    background-color: var(--white);
    border-radius: 5px;
}

.devices-count-input {
    width: 80%;
    height: 100%;
    color: var(--ash-variant-1);
}

.count-bubble {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 15%;
    height: 100%;
    padding-left: 5%;
    border-radius: 5px;
}

.select-battery-mode {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: var(--white);
    border-radius: 5px;
}

.new-request-add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 150%;
    background-color: var(--black);
    border-radius: 5px;
    cursor: pointer;
}

.new-request-add-button:disabled {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70%;
    height: 150%;
    background-color: var(--ash);
    border-radius: 5px;
    cursor: not-allowed;
}

.datetime-input {
    display: flex;
    width: 100%;
    height: 100%;
    border-radius: 5px;
}
