:root {
  --white: rgba(255, 255, 255, 1);
  --black: rgba(0, 0, 0, 1);
  --green: rgba(0, 200, 0, 1);
  --ash: rgba(140, 140, 140, 1);
  --ash-variant-1: rgba(140, 140, 140, 0.5);
  --seashell: rgba(241, 241, 239, 1);
  --pampas: rgba(244, 243, 240, 1);
  --cod-gray: rgba(10, 8, 2, 1);
  --cod-gray-variant: rgba(10, 8, 2, 0.6);
  --cod-gray-variant-1: rgba(10, 8, 2, 0.1);
  --rusty-red: rgba(222, 60, 65, 1);
  --alto: rgba(217, 217, 217, 1);
  --bg2: rgb(237, 237, 237);
  --border-radius: 8px;
  --sm-spacing: 8px;
  --background-primary: rgb(243 115 31);

  --font-size-xxs: 11px;
  --font-size-xs: 12px;
  --font-size-s: 14px;
  --font-size-ss: 16px;
  --font-size-x1: 20px;
  --font-size-s2: 16px;
  --font-size-m2: 18px;
  --font-size-12: 24px;

  --font-family-visuelt-bold: "Enphase Visuelt-Bold";
  --font-family-visuelt-medium: "Enphase Visuelt-Medium";
  --font-family-visuelt-regular: "Enphase Visuelt-Regular";
  --font-family-t-star_pro-bold: "T-Star Pro-Bold";
}


.enphasevisuelt-regural-normal-black-64px {
  color: var(--black);
  font-family: var(--font-family-visuelt-regular);
  font-size: 64px;
  font-weight: 400;
  font-style: normal;
}

.enphasevisuelt-bold-black-24px {
  color: var(--black);
  font-family: var(--font-family-visuelt-bold);
  font-size: var(--font-size-12);
  font-weight: 700;
  font-style: normal;
}

.enphasevisuelt-regular-normal-cod-gray-18px {
  color: var(--cod-gray);
  font-family: var(--font-family-visuelt-medium);
  font-size: var(--font-size-m2);
  font-weight: 500;
  font-style: normal;
}

.enphasevisuelt-medium-white-16px {
  color: var(--white);
  font-family: var(--font-family-visuelt-medium);
  font-size: var(--font-size-s2);
  font-weight: 500;
  font-style: normal;
}

.enphasevisuelt-medium-black-16px {
  color: var(--black);
  font-family: var(--font-family-visuelt-medium);
  font-size: var(--font-size-s2);
  font-weight: 500;
  font-style: normal;
}

.enphasevisuelt-regular-normal-rusty-red-14px {
  color: var(--rusty-red);
  font-family: var(--font-family-visuelt-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}

.enphasevisuelt-medium-black-32px {
  color: var(--black);
  font-family: var(--font-family-visuelt-medium);
  font-size: 32px;
  font-weight: 500;
  font-style: normal;
}

.enphasevisuelt-medium-black-20px {
  color: var(--black);
  font-family: var(--font-family-visuelt-medium);
  font-size: var(--font-size-x1);
  font-weight: 500;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
}

.enphasevisuelt-bold-black-12px {
  color: var(--black);
  font-family: var(--font-family-visuelt-bold);
  font-size: var(--font-size-ss);
  font-weight: 700;
  font-style: normal;
}

.enphasevisuelt-regural-normal-black-18px {
  color: var(--black);
  font-family: var(--font-family-visuelt-regular);
  font-size: 18px;
  font-weight: 400;
  font-style: normal;
}

.enphasevisuelt-regular-normal-rusty-green-14px {
  color: var(--green);
  font-family: var(--font-family-visuelt-regular);
  font-size: var(--font-size-ss);
  font-weight: 400;
  font-style: normal;
}