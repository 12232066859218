.container {
  height: auto;
  background-color: var(--background-primary);
  color: white;
}

.headerContent {
  min-height: 57px;
}

.headerText {
  font-size: 14px;
  font-weight: bold;
}

.logoImage {
  height: 28px;
  width: 28px;
  margin: 10px;
}

.pointer {
  cursor: pointer;
}

.avatar {
  background-color: transparent;
  margin-right: 10px;
  width: 30px;
  height: 30px;
  vertical-align: middle;
}

.avatarText {
  font-size: 14px;
}

.popoverText {
  font-size: 16px;
  color: #707070;
  font-weight: 600;
  padding: 10px 5px;
  text-align: center;
  word-break: break-all;
}

.typography {
  padding: 4px 8px;
  cursor: pointer;
  font-size: 15px;
  color: #6F6F6F;
}

.linkStyle {
  text-decoration: none;
  color: #6F6F6F;
}

.headerPaper {
  min-width: 200px;
  max-width: 300px;
  text-overflow: ellipsis;
  overflow: hidden;
}