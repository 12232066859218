.logout-view {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    min-width: 1000px;
    min-height: 700px;
    background: var(--cod-gray-variant);
    backdrop-filter: blur(25px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 11;
}

.logout-pop-up-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 30px;
    gap: 20px;

    position: relative;
    width: 35%;
    height: 20%;
    min-height: 20%;

    /* Light Theme / Light Gray */

    background: var(--white);
    border-radius: 25px;
}

.logout-text {
    position: relative;
    width: 100%;
    height: 40%;
    background-color: var(--white);
    display: flex;
    justify-content: center;
}

.logout-buttons {
    position: relative;
    width: 100%;
    height: 30%;
    background-color: var(--white);
    display: flex;
    justify-content: space-around;
}

.logout-button {
    position: relative;
    width: 20%;
    height: 100%;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black);
    cursor: pointer;
}

.logout-button:disabled {
    background-color: var(--ash);
    cursor: not-allowed;
}

.error-detail-span {
    color: var(--rusty-red);
    font-size: var(--font-size-ss);
    font-weight: 700;
    font-style: normal;
}
