.headerWrapper {
  justify-content: space-between;
  align-items: center;
  margin: 24px 40px;
  height: 100%;
}

.title {
  color: #335977;
  font-size: 30px;
  font-weight: bold;
}

.formContainer {
  box-shadow: 0px 2px 10px #3F434714;
  margin: 24px 40px;
  padding: 20px;
}

.formInput {
  max-width: 500
}

.submitButton {
  margin: 10px 8px;
}

.sliderWrapper {
  background-color: #FAFAFA;
  padding: 10px 10px 5px;
  margin: 10px 0px
}

.sliderText {
  color: #939497;
  font-size: 14px;
}

.sliderValueBox {
  background-color: white;
  color: #545455;
  margin: 5px 5px 5px 15px;
  padding: 5px;
  border: 0.2px solid #707070;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
}