.ButtonBox {
  max-width: 220px;
  min-height: 48px;
  background: #F37320;
  border-radius: 4px;
  margin: 10px auto;
  width: 100%;
  display: block;
  color: white !important;


}

.ButtonBox:hover {
  background: #F37320
}

.PlainButtonBox {
  border: #F37320 1px solid;
  background: #FFF;
  color: #F37320 !important;
}

.PlainButtonBox:hover {
  background: #FFF
}

.disable {
  opacity: 0.5;
  pointer-events: none
}